import Container    from '../../../components/Theme/Dashboard';
import Table    from '../../../components/Table';
import View from './View';
import Create from './Create';
import usePermissions from '../../../hooks/usePermissions';
import { Button, Grid } from '@mui/material';


const td  = [
  {
    label:"Nombre",
    field:"producto_name",
    className:"",
    align:"left",
  },

  {
    label:"Categoría",
    field:"label",
    className:"",
    align:"left",
  },

  {
    label:"Etiquetas",
    field:"tags",
    className:"",
    align:"left",
  },

  {
    label:"Precio",
    field:"price",
    className:"",
    align:"left",
  },
  
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        byIndex:{
                  prefixed:"./productos",
                  key:"id"
                },
      },
    ]
  },
]

const Home=(props)=>{
  const permissions     =   usePermissions("clientes","index",{
                                                                component:Table,
                                                                td:td,
                                                                href:"0"                                                                
                                                              });
  return  <Container>
            <Grid alignContent={"right"} justifyContent={"right"} sx={{mb:2}}>
              <Button sx={{mr:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap2cdopromocionales?refresh=true"} variant='contained' target='_blank'>
                Reiniciar Todo
              </Button>              
              <Button sx={{mr:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/paso1"} variant='contained' target='_blank'>
                Cargar Categorías
              </Button>              
              <Button sx={{mr:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap2cdopromocionales"} variant='contained' target='_blank'>
                Paso 1 Promocionales
              </Button>
              <Button sx={{mr:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap2promoopcioncolombia"} variant='contained' target='_blank'>
                Paso 1 Promoopcioncolombia
              </Button>
              <Button sx={{mr:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap2mppromocionales"} variant='contained' target='_blank'>
                Paso 1 Mppromocionales
              </Button>
              <Button sx={{mr:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap3cdopromocionales"} variant='contained' target='_blank'>
                Paso 2 Promocionales
              </Button>
              <Button sx={{mr:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap3promoopcioncolombia"} variant='contained' target='_blank'>
                Paso 2 Promoopcioncolombia
              </Button>
              <Button sx={{mr:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap3mppromocionales"} variant='contained' target='_blank'>
                Paso 2 Mppromocionales
              </Button>
            </Grid>
            {permissions.render()}
          </Container>
}
export default Home
