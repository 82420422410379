/*set components*/
import Home  from './Home';
/*set icons*/
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
];
