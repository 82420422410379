import {useState,useEffect} from 'react';
import useAxios      from '../../../hooks/useAxios';
import { useLocation } from "react-router-dom";
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import LinearWithLabel from '../../../components/Loading/LinearWithLabel';

let getInit
let axios
let endpoint

const App=({module})=>{
  axios                 =   useAxios()
  const location        =   useLocation();
  const [play,setPlay]  =   useState(null)
  const [data,setData]  =   useState({})
  const [send,setSend]  =   useState(false)

  const handlePlay  = ()  =>{
    setPlay(play?false:true)
  }

  endpoint = "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname+"/"

  getInit=(mounted)=>{
    if (!mounted) {
      return
    }

    axios.getData({},endpoint+(module||"link_transmision")).then((response)=>{
      if (response&&response.data) {
        setData(response.data)
        setPlay(false)
      }
    })
    // /console.log(axios.getData,endpoint_);
  }

  useEffect(()=>{
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  },[])


  useEffect(()=>{
    if (play&&send) {

      axios.getData({},endpoint+("send_"+module||"send_link_transmision")).then((response)=>{
        if (response&&response.data) {
          setData(response.data)
        }
      })
      setSend(false)
    }
  },[play,send,module])



  return  <Grid container spacing={2}>
            {
              data.total&&(
                <Grid item xs={12} md={2}>
                  <Grid>Total recipientes: <b>{data.total}</b></Grid>
                  {play&&(<LinearWithLabel getInit={getInit} percent={((parseFloat(data.total_entregado) * 100)/parseFloat(data.total))}/>)}
                </Grid>
              )
            }
            {
              data.total&&(
                <Grid item xs={12} md={2}>
                  <Grid>Total entregados: <b>{data.total_entregado}</b></Grid>
                  {play&&(<LinearWithLabel  increment={1} getInit={getInit}  setSend={setSend}/>)}
                </Grid>
              )
            }
            {
                play!==null&&(<Grid item xs={12} md={2}><Button variant="link" onClick={handlePlay}>{!play?<PlayCircleFilledWhiteIcon sx={{fontSize:30}}/>:<StopCircleIcon sx={{fontSize:30}}/>}</Button></Grid>)
            }

          </Grid>

}

export default App
