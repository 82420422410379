import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../../hooks/useFormData';
import Container    from '../../../../components/Theme/Form';
import Input        from '../../../../components/Input';
import Upload        from '../../../../components/Theme/Upload';
import useAxios      from '../../../../hooks/useAxios';

import { useLocation } from "react-router-dom";

//import Typography from '@mui/material/Typography';

// const data={
//   name:"Torre 3 Apartamento 104",
//   celular:3115000926,
//   superficie:103,
//   habitaciones:1,
//   closets:1,
//   banos:1,
//   descripcion:"Apartamento muy bonito"
// }

const Edit = ({handleClose, data , getInit, action}) => {
  const location          =   useLocation();
  const axios             =   useAxios();
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const endpoint  =   "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname+"/inmuebles"

  const onSubmit=(e)=>{
    e.preventDefault()
    //return console.log(endpoint);
    if (!formData.id) {
      axios.postData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }else {
      axios.putData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }
  }

  React.useEffect(()=>{
    if (data) {
      setFormData(data)
    }
  },[data,setFormData])


  return (  <form onSubmit={onSubmit}>
              <Container >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} sx={{mb:2}}>
                      <Grid item xs={12}>
                        <Input placeholder="Ej: Torre 3 Apto 104" defaultValue={data.name} required onChange={onChange} size="small" fullWidth name="name" type="text" label="Nombre"/>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mb:2}}>
                      <Grid item xs={12} md={6}>
                        <Input placeholder="Celular o Nro Fijo"  type="number" defaultValue={data.celular}  onChange={onChange} size="small" fullWidth name="celular"  label="Teléfono"/>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input placeholder="Ej: 120" type="number" defaultValue={data.superficie}  onChange={onChange} size="small" fullWidth name="superficie" label="Superficie en Mtrs"/>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mb:2}}>
                      <Grid item xs={12} md={4}>
                        <Input placeholder="Ej: 1" type="number" defaultValue={data.habitaciones}  onChange={onChange} size="small" fullWidth name="habitaciones" label="Habitaciones"/>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Input placeholder="Ej: 1" type="number" defaultValue={data.closets}  onChange={onChange} size="small" fullWidth name="closets" label="Closets"/>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Input placeholder="Ej: 1" type="number" defaultValue={data.banos}  onChange={onChange} size="small" fullWidth name="banos" label="Baños"/>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} >
                      <Grid item xs={12}>
                        <Input placeholder="Ej: Apartamento con vista a la montaña, amplios espacios..." defaultValue={data.descripcion} onChange={onChange} size="small" fullWidth name="descripcion" type="text" label="Descripción General"/>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="right">
                      <Grid item xs={12} align="right" sx={{mt:2}}>
                        <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                          Cancelar
                        </Button>
                        <Button variant="contained" type="submit">
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </form>
          );
};

export default Edit;
