import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../../hooks/useFormData';
import Container    from '../../../../components/Theme/Form';
import Input        from '../../../../components/Input';
import Upload        from '../../../../components/Theme/Upload';
import useAxios      from '../../../../hooks/useAxios';
import { useLocation } from "react-router-dom";
//import Typography from '@mui/material/Typography';

// const data={
//   name:"Parqueadero 1-11",
//   descripcion:"Amplio techo"
// }

const Edit = ({handleClose, data , getInit, action}) => {
  const location          =   useLocation();
  const axios             =   useAxios();
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    const endpoint  =   "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname+"/parqueaderos"
    //return console.log(endpoint);
    if (!formData.id) {
      axios.postData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }else {
      axios.putData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }
  }

  React.useEffect(()=>{
    if (data) {
      setFormData(data)
    }
  },[data,setFormData])


  return (  <form onSubmit={onSubmit}>
              <Container >
                <Grid container spacing={2} sx={{mb:2}}>
                  <Grid item xs={12}>
                    <Input placeholder="Ej: PArqueadero 101" defaultValue={data.name} required onChange={onChange} size="small" fullWidth name="name" type="text" label="Nombre"/>
                  </Grid>
                </Grid>
                <Grid container spacing={2} >
                  <Grid item xs={12}>
                    <Input placeholder="Ej: techo amplio apartamento 10 torre 3" defaultValue={data.descripcion} onChange={onChange} size="small" fullWidth name="descripcion" type="text" label="Descripción General"/>
                  </Grid>
                </Grid>
                <Grid container justifyContent="right">
                  <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                    <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                      Cancelar
                    </Button>
                    <Button variant="contained" type="submit">
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </form>
          );
};

export default Edit;
