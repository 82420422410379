import React from 'react';
import Container    from '../../../../components/Theme/OpenContent';
import useAxios      from '../../../../hooks/useAxios';
import useFormData  from '../../../../hooks/useFormData';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from './Tabs';
let axios;
const OpenView = () => {
  axios                   =   useAxios();
  const { formData,
          setFormData }   =   useFormData();

  React.useEffect(()=>{

    const getInit=()=>{
      axios.getData({}).then((response)=>{
        if (response&&response.data) {
          setFormData(response.data)
        }
      })
    }
    getInit()
  },[setFormData])

  return  <Container >


            { formData.id&&(
                <>
                  <Typography className="title-h3 title-h2" component="div">
                    {formData.name}
                  </Typography>
                  <Typography className="title-h3 title-h6" component="div">
                    {formData.cliente}
                  </Typography>
                  <Typography className="title-h3 title-h6" component="div">
                    {formData.email} / {formData.celular}
                  </Typography>
                  <Paper sx={{mt:2}}>
                    <Tabs data={formData}/>
                  </Paper>
                </>
              )
            }



          </Container>
};

export default OpenView;
