import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../../hooks/useFormData';
import Container    from '../../../../components/Theme/Form';
import Input        from '../../../../components/Input';
import useAxios      from '../../../../hooks/useAxios';

import { useLocation } from "react-router-dom";

//import Typography from '@mui/material/Typography';

// const data={
//   name:"Torre 3 Apartamento 104",
//   celular:3115000926,
//   superficie:103,
//   habitaciones:1,
//   closets:1,
//   banos:1,
//   descripcion:"Apartamento muy bonito"
// }

const Edit = ({handleClose, data , getInit, action}) => {
  const location          =   useLocation();
  const axios             =   useAxios();
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const endpoint  =   "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname+"/boletas"

  const onSubmit=(e)=>{
    e.preventDefault()
    //return console.log(endpoint);
    if (!formData.id) {
      axios.postData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }else {
      axios.putData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }
  }

  React.useEffect(()=>{
    if (data) {
      setFormData(data)
    }
  },[data,setFormData])


  return (  <form onSubmit={onSubmit}>
              <Container >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} sx={{mb:2}}>
                      <Grid item xs={12} md={3}>
                        <Input required placeholder="Nombre" type="text" defaultValue={data.name}  onChange={onChange} size="small" fullWidth name="name" label="Nombre"/>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Input required placeholder="Desde" type="date" defaultValue={data.desde}  onChange={onChange} size="small" fullWidth name="desde" label="Desde"/>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Input required placeholder="Hasta" type="date" defaultValue={data.hasta}  onChange={onChange} size="small" fullWidth name="hasta" label="Hasta"/>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Input required placeholder="Ej: 120000" type="number" defaultValue={data.precio}  onChange={onChange} size="small" fullWidth name="precio" label="Precio"/>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="right">
                      <Grid item xs={12} align="right" sx={{mt:2}}>
                        <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                          Cancelar
                        </Button>
                        <Button variant="contained" type="submit">
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </form>
          );
};

export default Edit;
