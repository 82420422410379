import Table    from '../../../../components/Table';
import View from './ViewAgenda';
import usePermissions from '../../../../hooks/usePermissions';


const td  = [
  {
    label:"Nombre (No es público)",
    field:"name",
    className:"",
    align:"left",
  },
  {
    label:"Título",
    field:"titulo",
    className:"",
    align:"left",
  },
  {
    label:"Subtítulo",
    field:"subtitulo",
    className:"",
    align:"left",
  },
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        component:View,
      },
    ]
  },
]

const Home=(props)=>{
  const permissions     =   usePermissions("clientes","index",{
                                                                component:Table,
                                                                td:td,
                                                                create:View,
                                                                subFixed:"/agenda"
                                                              });
  return  permissions.render()
}
export default Home
