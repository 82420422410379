import Table    from '../../../../components/Table';
import usePermissions from '../../../../hooks/usePermissions';


const td  = [
  {
    label:"Descripción",
    field:"descripcion",
    className:"",
    align:"left",
  },
  {
    label:"Nombre",
    field:"nombre",
    className:"",
    align:"left",
  },
  {
    label:"Link",
    field:"url",
    className:"",
    align:"left",
  },

  // {
  //   label:"Acción",
  //   field:"event",
  //   className:"",
  //   align:"center",
  //   event:[
  //     {
  //       label:"Ver",
  //       action:"view",
  //       component:View,
  //     },
  //   ]
  // },
]

const Home=(props)=>{
  const permissions     =   usePermissions("clientes","index",{
                                                                customAdd:true,
                                                                component:Table,
                                                                td:td,
                                                                subFixed:"/documentos"
                                                              });
  return  permissions.render()
}
export default Home
