import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../../hooks/useFormData';
import Container    from '../../../../components/Theme/Form';
import useAxios      from '../../../../hooks/useAxios';
import { useLocation } from "react-router-dom";
import anverso        from '../../../../assets/png/anverso.png';
import reverso        from '../../../../assets/png/reverso.png';
//import useAsyncStorage     from '../../../../hooks/useAsyncStorage';
import { List, ListItem, ListItemText } from '@mui/material';
//import Typography from '@mui/material/Typography';

// const data={
//   name:"Plaza Principal",
//   descripcion:"Amplio salón de fiestas, alquiler por $50 USD por 4 horas"
// }

const Edit = ({handleClose, data , getInit, action}) => {

  //const inmueblesStorage  =   useAsyncStorage("inmuebles");
  const location          =   useLocation();
  const axios             =   useAxios();
  const { formData,
          setFormData }   =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    const endpoint  =   "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname+"/personal_inmuebles"
    axios.postData(formData,endpoint).then((response)=>{
      if (response&&response.data&&response.data.id&&getInit) {
        getInit(true)
        handleClose()
      }
    })
  }

  React.useEffect(()=>{
    if (data) {
      let insert  =  {...data}
      if (!insert.cedula_frontal) {
        insert.cedula_frontal =   anverso
      }
      if (!insert.cedula_posterior) {
        insert.cedula_posterior =   reverso
      }
      insert.roles=[]
      setFormData(insert)
    }
  },[data,setFormData])

  const handleDisabled=(row)=>{
    const endpoint  =   "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname+"/personal_inmuebles"
    axios.putData(row,endpoint).then((response)=>{
      if (response&&response.data&&response.data.id&&getInit) {
        getInit(true)
        handleClose()
      }
    })
  }


  return (  <form onSubmit={onSubmit}>
              <Container >
                {
                  formData&&formData.personas&&(
                    <List component="ul">
                      {
                        formData.personas.map((row,key)=>{
                          return  <ListItem component="li" key={key} sx={{p:0,m:0}}>
                                    <ListItemText primary={"("+row.estatus+") "+row.tipo} secondary={row.name+" "+row.surnames+" - Celular:"+row.celular+" - Cédula:"+row.cedula} />
                                    {
                                      row.ma_estatus_id_usuario===8&&(
                                        <Button variant="contained" color="secondary" onClick={()=>handleDisabled(row)}>
                                          Inactivar como {row.tipo}
                                        </Button>
                                      )
                                    }

                                  </ListItem>
                        })
                      }

                    </List>
                  )
                }
                <Grid container justifyContent="right">
                  <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                    <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                      Cerrar
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </form>
          );
};

export default Edit;
