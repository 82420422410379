/*set components*/
import Home  from './Home';
/*set icons*/
import AccountTreeIcon from '@mui/icons-material/AccountTree';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <AccountTreeIcon />,
    title: "Proyectos",
    subtitle: "Gestión eficiente de los recursos"
  },
];
