import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import Inmuebles from './Inmuebles';
import AreasComunes from './AreasComunes';
import Parqueaderos from './Parqueaderos';
import Personal from './Personal';
import Documentos from './Documentos';


const modulos=[
  {
    label:"Inmuebles",
    component:Inmuebles
  },
  {
    label:"Areas Comunes",
    component:AreasComunes
  },
  {
    label:"Parqueaderos",
    component:Parqueaderos
  },
  {
    label:"Comunidad",
    component:Personal
  },
  {
    label:"Gastos",
    component:false
  },
  {
    label:"Asambleas",
    component:false
  },
  {
    label:"Documentos",
    component:Documentos
  }
]

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ExampleTabs = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
            <div>
              <Tabs value={tabValue} onChange={handleTabChange}>
                {
                  modulos.map((row,key)=>{
                    return <Tab label={row.label} id={"tab-"+key} key={key} />
                  })
                }
              </Tabs>
              {
                  modulos.map((row,key)=>{
                    return  <TabPanel value={tabValue} index={key} key={key}>
                              {row.component&&(<row.component></row.component>)}
                            </TabPanel>
                  })
              }

            </div>
  );
};

export default ExampleTabs;
