import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import Boletas from './Boletas';
import Agenda from './Agenda';
import Participantes from './Participantes';
import Gestores from './Gestores';
import Certificado from './Certificado';
import Recordatorio from './Recordatorio';
import EntregaCertificado from './EntregaCertificado';



const modulos=[
  {
    label:"Boletas Ventas",
    component:Boletas
  },
  {
    label:"Agenda",
    component:Agenda
  },
  {
    label:"Inscritos",
    component:Participantes
  },
  {
    label:"Gestores del evento",
    component:Gestores
  },
  {
    label:"Certificado",
    component:Certificado
  },
  {
    label:"Entrega de Certificado",
    component:EntregaCertificado,
  },
  {
    label:"Recordatorio",
    component:Recordatorio
  },
]

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ExampleTabs = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
            <div>
              <Tabs value={tabValue} onChange={handleTabChange}>
                {
                  modulos.map((row,key)=>{
                    return <Tab label={row.label} id={"tab-"+key} key={key} />
                  })
                }
              </Tabs>
              {
                  modulos.map((row,key)=>{
                    return  <TabPanel value={tabValue} index={key} key={key}>
                              {row.component&&(<row.component></row.component>)}
                            </TabPanel>
                  })
              }

            </div>
  );
};

export default ExampleTabs;
