import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../../hooks/useFormData';
import Container    from '../../../../components/Theme/Form';
import Input        from '../../../../components/Input';
import useAxios      from '../../../../hooks/useAxios';
import { useLocation } from "react-router-dom";

const Edit = ({handleClose, data , getInit, action}) => {
  const location          =   useLocation();
  const axios             =   useAxios();
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const endpoint  =   "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname+"/agenda"

  const onSubmit=(e)=>{
    e.preventDefault()
    //return console.log(endpoint);
    if (!formData.id) {
      axios.postData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }else {
      axios.putData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }
  }

  React.useEffect(()=>{
    if (data) {
      setFormData(data)
    }
  },[data,setFormData])


  return (  <form onSubmit={onSubmit}>
              <Container >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} sx={{mb:2}}>
                      <Grid item xs={12} md={3}>
                        <Input required placeholder="Nombre" type="text" defaultValue={data.name}  onChange={onChange} size="small" fullWidth name="name" label="Nombre"/>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Input required placeholder="Título" type="text" defaultValue={data.titulo}  onChange={onChange} size="small" fullWidth name="titulo" label="Título"/>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Input placeholder="Subtítulo" type="text" defaultValue={data.subtitulo}  onChange={onChange} size="small" fullWidth name="subtitulo" label="Subtítulo"/>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="right">
                      <Grid item xs={12} align="right" sx={{mt:2}}>
                        <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                          Cancelar
                        </Button>
                        <Button variant="contained" type="submit">
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </form>
          );
};

export default Edit;
