import React from 'react';
import Table    from '../../../../components/Table';
import View from './ViewParticipantes';
import usePermissions from '../../../../hooks/usePermissions';
import useAsyncStorage     from '../../../../hooks/useAsyncStorage';
import useAxios      from '../../../../hooks/useAxios';
import Emails from '../../Emails/Component';

const td  = [
  {
    label:"Nombre",
    field:"name",
    className:"",
    align:"left",
  },
  {
    label:"Apellidos",
    field:"surnames",
    className:"",
    align:"left",
  },
  {
    label:"Correo electrónico",
    field:"email",
    className:"",
    align:"left",
  },
  {
    label:"Teléfono",
    field:"celular",
    className:"",
    align:"left",
  },
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        component:View,
      },
      {
        label:"Comentarios",
        action:"comments",
        subFixed:"participante_evento_id_",
        component:true,
      },
      {
        label:"PDF",
        action:"pdf",
        component:true,
      },
    ]
  },
]
let axios;
let userStorage;
const Home=(props)=>{
  const [reset,setReset]=   React.useState(true)
  axios                 =   useAxios();
  userStorage           =   useAsyncStorage("perfiles");
  const permissions     =   usePermissions("clientes","index",{
                                                                skipSearch:"all",
                                                                download:true,
                                                                component:Table,
                                                                td:td,
                                                                create:View,
                                                                subFixed:"/Participante de Evento"
                                                              });

  React.useEffect(()=>{
    if (!userStorage.data) {
      const getInit=()=>{
        const endpoint    =  "api/"+process.env.REACT_APP_BACKEND_VERSION+"/info/roles"
        axios.getData({},endpoint).then((response)=>{
          if (response&&response.data) {
            userStorage.setData(response.data);
          }
        })
      }
      getInit()
    }
  },[])
  return  <>
            <Emails setReset={setReset}/>
            {!reset&&(permissions.render())}
          </>
}
export default Home
