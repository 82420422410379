import React from 'react';
import Grid  from  '@mui/material/Grid';
import Paper  from  '@mui/material/Paper';
import Button  from  '@mui/material/Button';
import useAxios      from '../../../hooks/useAxios';
import Input        from '../../../components/Input';
import Comentarios        from '../../../components/Comentarios';
import Select from '../../../components/Select';
import { useParams } from 'react-router-dom';

const BACKEND = window.location.hostname === 'localhost' ? process.env.REACT_APP_BACKEND : process.env.REACT_APP_BACKENDREMOTE;

function formatearMontoColombia(monto) {
  // Convertir el monto a un número entero
  const montoEntero = Math.floor(monto);

  // Separar en miles con comas
  const montoFormateado = montoEntero.toLocaleString("es-CO");

  return montoFormateado;
}


const Edit = () => {
  const [prices, setPrices]         =   React.useState({});
  const [data,setData]              =   React.useState({})
  const axios                       =   useAxios();
  const params                      =   useParams();
  

  React.useEffect(()=>{

    const getInit=()=>{
      axios.getData({}).then((response)=>{
        if (response&&response.data&&response.data.items) {
          let data_ = []
          response.data.items.map((row)=>{
            if (row.cantidad===true) {
              return data_.push({  
                            ...row,
                            cantidad:1
                          })  
            }else{
              return data_.push(row)
            }  
            return          
          })
          setData({...response.data,items:data_})
        }
      })
    }

    getInit()

  },[])

  const onChange=(event, itemId, key)=>{
    
    const newPrices = {
      ...prices,
      [itemId.id]: parseFloat(event.target.value),
    };
    
    if(event.target.name==='price'){
      setPrices(newPrices);
    }    

    axios.postData({  
                      key:key,
                      id:itemId.id, 
                      value:event.target.value, 
                      name:event.target.name
                    },"api/v1"+document.location.pathname)


  }

  
  const onChangeSelect=(data__,key,e)=>{
    axios.postData({  
                      key:key,
                      id:e.id,
                      value:e.disponible,
                      name:"disponible"                   
                    })
  }

  return  <Grid>
            <Grid>Cotización:<b>0000{data.id}</b></Grid>
            <Grid>Cliente:<b>{data.cliente}</b></Grid>
            <Grid>Monto Total:<b>00001</b></Grid>
            <Grid>fecha:<b>{data.total}</b></Grid>
            <Grid sx={{mt:2}}>
              <Button sx={{mr:2}} variant="contained" component="a" href={BACKEND+"api"+document.location.pathname+"?token="+data.token} target="_blank">
                Abrir Cotización para el cliente
              </Button>
              <Button variant="contained" component="a" href={BACKEND+"api"+document.location.pathname+"?token="+data.token+"&send=true"} target="_blank">
                Enviar Cotización al cliente
              </Button>
            </Grid>
            <Grid container sx={{mt:2}}>
              <Grid item xs={12} md={8}>
                <Paper sx={{p:2}}>
                  <Grid container spacing={1} sx={{background:"#333",color:"white",p:2}}>
                    <Grid item xs={3} className="white">
                      Producto
                    </Grid>
                    <Grid item xs={4} className="white">
                      Fotos
                    </Grid>
                    <Grid item xs={1} align="center" className="white">
                      Cantidad
                    </Grid>
                    <Grid item xs={2} align="center" className="white">
                      Valores antes de IVA
                    </Grid>
                    <Grid item xs={2} align="right" className="white">
                      Total
                    </Grid>
                  </Grid>

                  {data&&data.items&&data.items.length>0&&(
                    data.items.map((row,key)=>{
                      const summary   =   JSON.parse(row.summary)
                      return  <Grid container spacing={1} sx={{p:2}} key={key}>
                                  <Grid item xs={3} >
                                    <div><b>{row.nombre_producto}</b></div>
                                    <div dangerouslySetInnerHTML={{ __html: row.description }} />
                                  </Grid>
                                  <Grid item xs={4} >
                                  <Input fullWidth defaultValue={row.comentario} onChange={(e) => onChange(e, row, key)} size="small" name="comentario"  label="Comentario"/>
                                    {summary&&summary.imagenes&&(
                                      <Grid container>
                                        {
                                          summary.imagenes.map((row2,key2)=>{
                                            return  <Grid item xs={2} key={key2}>
                                                      <a href={row2} target="_blank">
                                                        <img className="img-fluid" src={row2} alt="" />
                                                      </a>
                                                    </Grid>
                                          })
                                        }
                                      </Grid>
                                      
                                    )}

                                  </Grid>
                                  <Grid item xs={1} align="center">
                                    <Input defaultValue={row.cantidad} onChange={(e) => onChange(e, row, key)} size="small" name="cantidad" type="number" label="Cantidad"/>
                                  </Grid>
                                  <Grid item xs={2} align="right" >
                                    <Input defaultValue={row.price} onChange={(e) => onChange(e, row, key)} size="small" name="price" type="number" label="Precio"/>
                                    <Select placeholder="Disponibilidad" 
                                            items={[
                                              {
                                                label:"Disponible",
                                                id:"Disponible"
                                              },
                                              {
                                                label:"No Disponible",
                                                id:"No Disponible"
                                              },
                                            ]}
                                            defaultValue={row.disponible} 
                                            data={row}
                                            onChange={(e)=>onChangeSelect(row, key,e)} 
                                            name="disponible" 
                                            label="Disponibilidad"
                                    />
                                  </Grid>
                                  <Grid item xs={2} align="right" >
                                    {formatearMontoColombia(parseFloat(prices[row.id] || row.price) * parseFloat(row.cantidad))}
                                  </Grid>
                              </Grid>
                    })

                  )}

                </Paper>
              </Grid>
              {
                params&&params["*"]&&(
                  <Grid item xs={12} md={4}>
                    <Comentarios data={{id:params["*"]}}/>                             
                  </Grid>
                )
              }
              
            </Grid>
          </Grid>
};

export default Edit;
