import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import Typography  from  '@mui/material/Typography';
import useFormData  from '../../../../hooks/useFormData';
import Container    from '../../../../components/Theme/Form';
import Input        from '../../../../components/Input';
import Avatar        from '../../../../components/Avatar';
import ImageFluid    from '../../../../components/Avatar/Fluid';
import Upload        from '../../../../components/Theme/Upload';
import useAxios      from '../../../../hooks/useAxios';
import { useLocation } from "react-router-dom";
import anverso        from '../../../../assets/png/anverso.png';
import reverso        from '../../../../assets/png/reverso.png';
import Autocomplete        from '../../../../components/Autocomplete';
import useAsyncStorage     from '../../../../hooks/useAsyncStorage';
//import Typography from '@mui/material/Typography';

// const data={
//   name:"Plaza Principal",
//   descripcion:"Amplio salón de fiestas, alquiler por $50 USD por 4 horas"
// }

const Edit = ({handleClose, data , getInit, action}) => {
  const userStorage       =   useAsyncStorage("perfiles");
  //const inmueblesStorage  =   useAsyncStorage("inmuebles");
  const location          =   useLocation();
  const axios             =   useAxios();
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    const endpoint  =   "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname+"/personal"

    if (!formData.id) {
      axios.postData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }else {
      axios.putData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }
  }

  React.useEffect(()=>{
    if (data) {
      let insert  =  {...data}
      if (!insert.cedula_frontal) {
        insert.cedula_frontal =   anverso
      }
      if (!insert.cedula_posterior) {
        insert.cedula_posterior =   reverso
      }
      insert.roles=[]
      setFormData(insert)
    }
  },[data,setFormData])

  return (  <form onSubmit={onSubmit}>
              <Container >
                <Grid sx={{mb:4,}} align="center">
                  <Upload onChange={setFormData} data={formData} name="avatar">
                    <Avatar sx={{width:100,height:100}} src={formData.avatar||data.avatar} />
                  </Upload>
                </Grid>

                <Grid sx={{mb:4,}} align="center">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {
                        data.rol || data.cargo?<>
                          <Typography gutterBottom variant="h5" component="div" className="title-h3 ">
                            Rol: <b className="border-bottom">{data.rol||data.cargo}</b>
                          </Typography>
                        </>:<Autocomplete name="rol" data={data} setFormData={setFormData} fixedData={userStorage.data}/>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{mb:2}}>
                  <Grid item xs={12} md={6}>
                    <Upload onChange={setFormData} data={formData} name="cedula_frontal">
                      <ImageFluid src={formData.cedula_frontal}/>
                    </Upload>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Upload onChange={setFormData} data={formData} name="cedula_posterior">
                      <ImageFluid src={formData.cedula_posterior}/>
                    </Upload>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{mb:2}}>
                  <Grid item xs={12} md={6}>
                    <Input placeholder="Nombres" defaultValue={data.name} required onChange={onChange} size="small" fullWidth name="name" type="text" label="Nombres"/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input placeholder="Apellidos" defaultValue={data.surnames} required onChange={onChange} size="small" fullWidth name="surnames" type="text" label="Apellidos"/>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{mb:2}}>
                  <Grid item xs={12} md={6}>
                    <Input placeholder="Email" defaultValue={data.email} required onChange={onChange} size="small" fullWidth name="email" type="text" label="Email"/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input placeholder="Celular" defaultValue={data.celular} required onChange={onChange} size="small" fullWidth name="celular" type="text" label="Celular"/>
                  </Grid>
                </Grid>
                <Grid container justifyContent="right">
                  <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                    <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                      Cancelar
                    </Button>
                    <Button variant="contained" type="submit">
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </form>
          );
};

export default Edit;
