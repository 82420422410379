import Container    from '../../../components/Theme/Dashboard';
import Table    from '../../../components/Table';
import View from './View';
import Create from './Create';
import usePermissions from '../../../hooks/usePermissions';
import { Button, Grid } from '@mui/material';
import useAxios from '../../../hooks/useAxios';
import { useState } from 'react';


const td  = [
  {
    label:"Nombre",
    field:"label",
    className:"",
    align:"left",
  },
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        component:View,
      },
    ]
  },
]


const Home=(props)=>{
  const [loading,setloading]        =   useState(false);
  //const axios                       =   useAxios();

  const permissions     =   usePermissions("clientes","index",{
                                                                component:Table,
                                                                td:td,
                                                                create:Create
                                                              });
  /*
  const handleScrapping=()=>{
    setloading(true)
    axios.getData(false,"paso1").then((response)=>{
      setTimeout(() => {
        setloading(false)  
      }, 1500);      
      if (response) {
        console.log(response)
      }
    })
  } 
    */

  return  <Container>
            <Grid alignContent={"right"} justifyContent={"right"}>
              <Button component="a" href={document.location.origin.replace("3000","8000")+"/paso1"} variant='contained' target='_blank'>
                Cargar Categorías
              </Button>
            </Grid>
            {
              !loading&&(
                <>
                  {
                    permissions.render()
                  }
                </>
              )
            }
          </Container>
}
export default Home
