import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../../hooks/useFormData';
import Container    from '../../../../components/Theme/Form';
import Input        from '../../../../components/Input';
import Avatar        from '../../../../components/Avatar';
import Upload        from '../../../../components/Theme/Upload';
import useAxios      from '../../../../hooks/useAxios';
import { useLocation } from "react-router-dom";

const Edit = ({handleClose, data , getInit, action}) => {
  const location          =   useLocation();
  const axios             =   useAxios();
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    const endpoint  =   "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname+"/certificado"
    //return console.log(endpoint);
    if (!formData.id) {
      axios.postData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }else {
      axios.putData(formData,endpoint).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }
  }

  React.useEffect(()=>{
    if (data) {
      let insert  =   {...data}
      insert.rol  =   "Gestor de Eventos";
      setFormData(insert)
    }
  },[data,setFormData])

  return (  <form onSubmit={onSubmit}>
              <Container >
                <Grid sx={{mb:4,}} align="center">
                  <Upload onChange={setFormData} data={formData} name="avatar" aspect={4/3}>
                    <Avatar sx={{width:100,height:100}} src={formData.avatar||data.avatar} />
                  </Upload>
                </Grid>
                <Grid container spacing={2} sx={{mb:2}}>
                  <Grid item xs={12} md={4}>
                    <Input placeholder="Nombres" defaultValue={data.name} required onChange={onChange} size="small" fullWidth name="name" type="text" label="Nombres"/>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Input placeholder="Variable de ubicación" defaultValue={data.variable} required onChange={onChange} size="small" fullWidth name="variable" type="text" label="Variable de ubicación"/>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Input placeholder="Posición" defaultValue={data.posicion} required onChange={onChange} size="small" fullWidth name="posicion" type="text" label="Posición"/>
                  </Grid>
                </Grid>
                <Grid container justifyContent="right">
                  <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                    <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                      Cancelar
                    </Button>
                    <Button variant="contained" type="submit">
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </form>
          );
};

export default Edit;
