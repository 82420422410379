import Table    from '../../../../components/Table';
import View from './ViewBoletas';
import usePermissions from '../../../../hooks/usePermissions';


const td  = [
  {
    label:"Desde",
    field:"desde",
    className:"",
    align:"left",
  },
  {
    label:"Hasta",
    field:"hasta",
    className:"",
    align:"left",
  },
  {
    label:"Precio",
    field:"precio",
    className:"",
    align:"left",
  },
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        component:View,
      },
      {
        label:"Comentarios",
        action:"comments",
        subFixed:"boletas_id_",
        component:true,
      },
    ]
  },
]

const Home=(props)=>{
  const permissions     =   usePermissions("clientes","index",{
                                                                skipSearch:"input",
                                                                component:Table,
                                                                td:td,
                                                                create:View,
                                                                subFixed:"/boletas"
                                                              });
  return  permissions.render()
}
export default Home
