import {useEffect} from 'react';
import Grid  from  '@mui/material/Grid';
import { Card, CardContent, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Container    from '../../components/Theme/Dashboard';
import useAxios      from '../../hooks/useAxios';
import useFormData  from '../../hooks/useFormData';
let getInit;
const Web=()=>{
  const axios                       =   useAxios();
  const { formData, setFormData }   =   useFormData();

  getInit=()=>{
    const endpoint  =   "api/"+process.env.REACT_APP_BACKEND_VERSION+window.location.pathname+""
    axios.getData({},endpoint).then((response)=>{
      if (response&&response.data) {
        setFormData(response.data)
      }
    })
  }

  useEffect(()=>{
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  },[])

  return  <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Card variant="cardHome">
                    <CardContent align="center">
                      <Typography variant="h5" component="div">
                        Clientes
                      </Typography>
                      <Typography variant="body2">
                        Total registrados 
                      </Typography>
                      <Typography variant="h5" component="div">
                        {formData.clientes}
                        {console.log(formData)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card variant="cardHome">
                    <CardContent align="center">
                      <Typography variant="h5" component="div">
                        Productos
                      </Typography>
                      <Typography variant="body2">
                        Total registrados 
                      </Typography>
                      <Typography variant="h5" component="div">
                        {formData.productos}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card variant="cardHome">
                    <CardContent align="center">
                      <Typography variant="h5" component="div">
                        Cotizaciones
                      </Typography>
                      <Typography variant="body2">
                        Total registrados 
                      </Typography>
                      <Typography variant="h5" component="div">
                        {formData.cotizaciones}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {
                  /*
                    <Grid item xs={12} md={3}>
                      <Card variant="cardHome">
                        <CardContent align="center">
                          <Typography variant="h5" component="div">
                            Etiquetas
                          </Typography>
                          <Typography variant="body2">
                            Total registrados 
                          </Typography>
                          <Typography variant="h5" component="div">
                            {formData.etiquetas}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  */
                }                
              </Grid>
          </Container>
}
export default Web

