/*set components*/
import Home  from './Home';
/*set icons*/
import Diversity1Icon from '@mui/icons-material/Diversity1';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <Diversity1Icon />,
    title: "Hojas de vida",
    subtitle: "Explora hojas de vida detalladas y encuentra candidatos ideales."
  },
];
