import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../hooks/useFormData';
import Container    from '../../../components/Theme/Form';
import Input        from '../../../components/Input';
import useAxios      from '../../../hooks/useAxios';
import Select from '../../../components/Select';;

let getInit

const Edit = ({handleClose, data , getInit}) => {
  const axios             =   useAxios();
  const [categories,setCategories]  = React.useState([])
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    axios.postData(formData).then((response)=>{
      if (response&&response.data&&getInit) {
        getInit(true)
        handleClose()
      }
    })
  }

  getInit=()=>{
    axios.getData({},"api/v1/productos/catalogos?select=true").then((response)=>{
      if (response&&response.data) {
        setCategories(response.data)        
      }
    })
  }

  React.useEffect(()=>{
    getInit()
    if (data) {
      setFormData(data)
    }
  },[data,setFormData])



  return (  <form onSubmit={onSubmit}>
              <Container >
                <Grid container spacing={2} sx={{mb:1}}>                  
                  <Grid item xs={12}>
                    <Input defaultValue={data.label} required onChange={onChange} size="small" fullWidth name="label" type="text" label="Nombre Categoría"/>
                  </Grid>
                  { 
                    categories&&categories.length>0&&(
                      <Grid item xs={12}>
                        <Select placeholder="Categoría" 
                                items={categories}
                                data={formData}
                                onChange={setFormData} 
                                name="categoria_id" 
                                label="Categoría Remota"
                        />
                      </Grid>                  
                    )
                  }
                  
                </Grid>
                <Grid container justifyContent="right">
                  <Grid item xs={12} md={6} align="right">
                    <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                      Cancelar
                    </Button>
                    <Button variant="contained" type="submit">
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </form>
          );
};

export default Edit;
