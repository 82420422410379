import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../hooks/useFormData';
import Container    from '../../../components/Theme/Form';
import Input        from '../../../components/Input';
import Avatar        from '../../../components/Avatar';
import Upload        from '../../../components/Theme/Upload';
import useAxios      from '../../../hooks/useAxios';

const Edit = ({handleClose, data , getInit}) => {
  const axios             =   useAxios();
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    axios.postData(formData).then((response)=>{
      if (response&&response.data&&response.data.id&&getInit) {
        getInit(true)
        handleClose()
      }
    })
  }

  React.useEffect(()=>{
    if (data) {
      setFormData(data)
    }
  },[data,setFormData])



  return (  <form onSubmit={onSubmit}>
              <Container >
                <Grid sx={{mb:4,}} align="center">
                  <Upload onChange={setFormData} data={formData} name="avatar">
                    <Avatar sx={{width:100,height:100}} src={formData.avatar||data.avatar} />
                  </Upload>
                </Grid>
                <Grid container spacing={2} sx={{mb:2}}>
                  <Grid item xs={12} md={6}>
                    <Input defaultValue={data.name} required onChange={onChange} size="small" fullWidth name="name" type="text" label="Nombre"/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input defaultValue={data.website} required onChange={onChange} size="small" fullWidth name="website" type="text" label="Website"/>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{mb:2}}>
                  <Grid item xs={12} md={6}>
                    <Input defaultValue={data.email} required onChange={onChange} size="small" fullWidth name="email" type="email" label="Correo electrónico"/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input defaultValue={data.celular}  required onChange={onChange} size="small" fullWidth name="celular" type="text" label="Celular"/>
                  </Grid>
                </Grid>
                <Grid container spacing={2} >
                  <Grid item xs={12} md={6}>
                    <Input defaultValue={data.rif} required onChange={onChange} size="small" fullWidth name="rif" type="text" label="RUT O NIT"/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input defaultValue={data.direccion} required onChange={onChange} size="small" fullWidth name="direccion" type="text" label="Dirección"/>
                  </Grid>
                </Grid>
                <Grid container justifyContent="right">
                  <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                    <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                      Cancelar
                    </Button>
                    <Button variant="contained" type="submit">
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </form>
          );
};

export default Edit;
