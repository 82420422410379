/*set components*/
import Home  from './Home';
/*set icons*/
import FaceIcon from '@mui/icons-material/Face';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <FaceIcon />,
    title: "Colaboradores",
    subtitle: "Mantén contacto directo con tu equipo para garantizare el éxito"
  },
];
