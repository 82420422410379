import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectVariants({items,data={},label,placeholder,name,defaultValue,onChange}) {
  const [age, setAge] = React.useState(defaultValue || '');

  const handleChange = (event) => {
    setAge(event.target.value);
    let _data   = {...data}
    _data[name] = event.target.value;
    onChange(_data)
  };

 

  return (    
      <FormControl variant="standard" sx={{ minWidth: "100%" }}>
        <InputLabel id="select-standard-label">
            {label}
        </InputLabel>
        <Select
          defaultValue={"Hombre"}
          labelId="select-standard-label"
          value={age}
          onChange={handleChange}
          label={label}
        >
          {
            placeholder&&(
              <MenuItem value="">
                <em>{placeholder}</em>
              </MenuItem>
            )
          }
          {
            name&&items&&(
              items.map((row,key)=>{
                return  <MenuItem value={row.id||row.value||row.label||key} key={key}>
                          {row.label}
                        </MenuItem>
              })
            )
          }
        </Select>
      </FormControl>
    
  );
}