import Table    from '../../../../components/Table';
import View from './ViewAreasComunes';
import usePermissions from '../../../../hooks/usePermissions';


const td  = [
  {
    label:"Inmueble",
    field:"name",
    className:"",
    align:"left",
  },
  {
    label:"Descripción",
    field:"descripcion",
    className:"",
    align:"left",
  },
  {
    label:"Condominio",
    field:"condominio",
    className:"",
    align:"left",
  },
  {
    label:"Teléfono",
    field:"celular",
    className:"",
    align:"left",
  },
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        component:View,
      },
      {
        label:"Comentarios",
        action:"comments",
        subFixed:"areas_comunes_id_",
        component:true,
      },
    ]
  },
]

const Home=(props)=>{
  const permissions     =   usePermissions("clientes","index",{
                                                                upload:true,
                                                                component:Table,
                                                                td:td,
                                                                create:View,
                                                                subFixed:"/areascomunes"
                                                              });
  return  permissions.render()
}
export default Home
