/*set components*/
import Dashboard  from './Dashboard';
import Clientes  from './Clientes';
import Condominios  from './Condominios';
import Eventos  from './Eventos';

import Productos  from './Productos';
import Categorias  from './Categorias';

import Proyectos  from './Proyectos';
import Cotizaciones  from './Cotizaciones';
import Colaboradores  from './Colaboradores';
import Vacantes  from './Vacantes';
import Curriculum  from './Curriculum';
import Content  from './Content';

/*icons*/
import Face3Icon from '@mui/icons-material/Face3';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BusinessIcon from '@mui/icons-material/Business';
import SellIcon from '@mui/icons-material/Sell';
import FaceIcon from '@mui/icons-material/Face';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import NewspaperIcon from '@mui/icons-material/Newspaper';


/*set icons*/
import HomeIcon from '@mui/icons-material/Home';

export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Dashboard,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Home",
    path: "home",
    component: Dashboard,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Clientes",
    url: "clientes",
    path: "clientes/*",
    component: Clientes,
    menu: true,
    private: true,
    icon: <Face3Icon />,
  },
  {
    name: "Condominios",
    url: "condominios",
    path: "condominios/*",
    component: Condominios,
    menu: false,
    private: true,
    icon: <BusinessIcon />,
  },
  {
    name: "Productos",
    url: "productos",
    path: "productos/*",
    component: Productos,
    menu: true,
    private: true,
    icon: <ConfirmationNumberIcon />,
  },
  {
    name: "Categorias",
    url: "categorias",
    path: "categorias/*",
    component: Categorias,
    menu: true,
    private: true,
    icon: <ConfirmationNumberIcon />,
  },
  {
    name: "Eventos",
    url: "eventos",
    path: "eventos/*",
    component: Eventos,
    menu: false,
    private: true,
    icon: <ConfirmationNumberIcon />,
  },
  {
    name: "Proyectos",
    url: "proyectos",
    path: "proyectos/*",
    component: Proyectos,
    menu: false,
    private: true,
    icon: <AccountTreeIcon />,
  },
  {
    name: "Cotizaciones",
    url: "cotizaciones",
    path: "cotizaciones/*",
    component: Cotizaciones,
    menu: true,
    private: true,
    icon: <SellIcon />,
  },
  {
    name: "Colaboradores",
    url: "colaboradores",
    path: "colaboradores/*",
    component: Colaboradores,
    menu: false,
    private: true,
    icon: <FaceIcon />,
  },
  {
    name: "Vacantes",
    url: "vacantes",
    path: "vacantes/*",
    component: Vacantes,
    menu: false,
    private: true,
    icon: <WorkHistoryIcon />,
  },
  {
    name: "Curriculum",
    url: "curriculum",
    path: "curriculum/*",
    component: Curriculum,
    menu: false,
    private: true,
    icon: <Diversity1Icon />,
  },
  {
    name: "Contenido",
    url: "content",
    path: "content/*",
    component: Content,
    menu: true,
    private: true,
    icon: <NewspaperIcon />,
  },
];
